<template>
  <div>
    <b-modal
      id="modal-import"
      centered
      hide-footer
      :title="modalTitle"
      class="pb-3"
    >
      <div>
        <b-row>
          <b-col
            col="12"
            md="12"
            class="d-flex align-items-center justify-content-end mb-1"
          >
            <b-form-file
              ref="file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              class="mr-1"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="handleImport()"
            >
              <feather-icon
                icon="UploadIcon"
              />
            </b-button>
          </b-col>
          <b-col
            col="12"
            md="12"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="mb-1"
              @click="downloadTemplate()"
            >
              Download Template
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, VBModal, BButton, BFormFile, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BButton,
    BFormFile,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    modalTitle: {
      type: String,
      default: 'Upload Images',
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      errors: [],
    }
  },
  methods: {
    handleImport() {
      const formData = new FormData()
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.$refs.file.files.length; i++) {
        formData.append('file', this.$refs.file.files[i])
      }

      this.$http.post('/v1/inventory/import', formData)
        .then(() => {
          this.$emit('success')

          // close modal
          this.$bvModal.hide('modal-import')

          // refresh data
          this.$emit('refresh')
        })
        .catch(error => {
          this.$emit('error', error)
        })
    },
    downloadTemplate() {
      this.$http.get('/v1/template/import', {
        responseType: 'blob',
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'template.xlsx')
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          this.$emit('error', error)
        })
    },
  },
}
</script>
