<template>
  <div>
    <b-modal
      id="modal-upload"
      centered
      hide-footer
      :title="modalTitle"
      class="pb-3"
    >
      <div
        v-if="detail.id !== undefined"
      >
        <b-list-group class="mb-2">
          <b-list-group-item
            v-for="image in detail.images"
            :key="image.id"
            class="d-flex justify-content-between align-items-center"
          >
            <b-img
              :src="image.url_path"
              :alt="image.url_path"
              rounded
              class="w-25"
            />
            <b-button
              variant="gradient-danger"
              class="btn-icon"
              @click="handleRemove(image.id)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-50"
              />
              <span>Remove</span>
            </b-button>
          </b-list-group-item>
        </b-list-group>
        <div class="d-flex align-items-center justify-content-end mb-1">
          <b-form-file
            ref="file"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            class="mr-1"
            multiple
          />
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="handleUpload()"
          >
            <feather-icon
              icon="UploadIcon"
            />
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, VBModal, BImg, BListGroup, BListGroupItem, BButton, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BImg,
    BListGroup,
    BListGroupItem,
    BButton,
    BFormFile,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    modalTitle: {
      type: String,
      default: 'Upload Images',
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      errors: [],
    }
  },
  methods: {
    handleUpload() {
      const formData = new FormData()
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.$refs.file.files.length; i++) {
        formData.append('images[]', this.$refs.file.files[i])
      }

      this.$http.post(`/v1/inventory/upload_images/${this.detail.id}`, formData)
        .then(() => {
          this.$emit('success')
          this.$emit('refresh', this.detail.id)
        })
        .catch(error => {
          this.$emit('error', error)
        })
    },
    handleRemove(imageId) {
      this.$http.delete(`/v1/inventory/remove_image/${imageId}`)
        .then(() => {
          this.$emit('success')
          this.$emit('refresh', this.detail.id)
        })
        .catch(error => {
          this.$emit('error', error)
        })
    },
  },
}
</script>
