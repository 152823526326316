<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            List Alat
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-select
                v-model="searchStatus"
                :options="statusList"
                class="d-inline-block mr-1"
              />
              <b-form-select
                v-model="searchCategory"
                :options="categoryList"
                class="d-inline-block mr-1"
              />
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="$can('create', 'Inventory Tool')"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-form
                class="mr-75"
                variant="primary"
                @click="handleCreate()"
              >
                <span class="text-nowrap">Tambah Alat</span>
              </b-button>
              <b-button
                v-if="$can('create', 'Inventory Tool')"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-import
                variant="secondary"
                class="btn-icon mr-75"
              >
                <feather-icon icon="UploadIcon" />
              </b-button>
              <b-button
                v-if="$can('create', 'Inventory Tool')"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="success"
                class="btn-icon"
                @click="handleExport()"
              >
                <feather-icon icon="DownloadIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-overlay
        :show="overlay"
        rounded="sm"
      >
      <b-table-simple
          striped
          caption-top
          responsive
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th class="text-center">Kategori</b-th>
              <b-th class="text-center">Nama</b-th>
              <b-th class="text-center">Kode</b-th>
              <b-th class="text-center">Status</b-th>
              <b-th class="text-center">Lokasi</b-th>
              <b-th class="text-center">Action</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template
              v-if="fetchData.length > 0"
              v-for="(item, index) in fetchData"
            >
              <b-tr>
                <b-th :rowspan="item.total">{{ item.name }}</b-th>
                <b-th class="text-right">{{ item.inventories[0].name }}</b-th>
                <b-td class="text-center">{{ item.inventories[0].inventory_code }}</b-td>
                <b-td class="text-center">
                  <b-badge
                    pill
                    :variant="`light-${resolverStatus(item.inventories[0].status)}`"
                    class="text-capitalize"
                  >
                    {{ translateStatus(item.inventories[0].status) }}
                  </b-badge>
                  <br>
                  <small
                    v-if="item.inventories[0].status == 'unavailable' && item.inventories[0].available_date"
                    class="text-small text-secondary"
                  >
                    s.d {{ item.inventories[0].available_date }}
                  </small>
                </b-td>
                <b-td class="text-center">{{ item.inventories[0].location }}</b-td>
                <b-td class="text-center">
                  <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >

                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>

                      <b-dropdown-item
                        v-b-modal.modal-detail
                        @click="handleDetail(item.inventories[0].id)"
                      >
                        <feather-icon icon="EyeIcon" />
                        <span class="align-middle ml-50">Detail</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="$can('update', 'Inventory Facility')"
                        v-b-modal.modal-upload
                        @click="handleUpload(item.inventories[0].id)"
                      >
                        <feather-icon icon="UploadIcon" />
                        <span class="align-middle ml-50">Upload Image</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="$can('update', 'Inventory Facility')"
                        v-b-modal.modal-form
                        @click="handleEdit(item)"
                      >
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Edit</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="$can('update', 'Inventory Facility')"
                        @click="handleDelete(item.inventories[0].id)"
                      >
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">Delete</span>
                      </b-dropdown-item>
                    </b-dropdown>
                </b-td>
              </b-tr>
              <b-tr
                v-for="(item, index) in item.inventories"
                :key="index"
                v-if="index > 0"
              >
                <b-th class="text-right">{{ item.name }}</b-th>
                <b-td class="text-center">{{ item.inventory_code }}</b-td>
                <b-td class="text-center">
                  <b-badge
                    pill
                    :variant="`light-${resolverStatus(item.status)}`"
                    class="text-capitalize"
                  >
                    {{ translateStatus(item.status) }}
                  </b-badge>
                  <br>
                  <small
                    v-if="item.status == 'unavailable' && item.available_date"
                    class="text-small text-secondary"
                  >
                    s.d {{ item.available_date }}
                  </small>
                </b-td>
                <b-td class="text-center">{{ item.location }}</b-td>
                <b-td class="text-center">
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>

                    <b-dropdown-item
                      v-b-modal.modal-detail
                      @click="handleDetail(item.id)"
                    >
                      <feather-icon icon="EyeIcon" />
                      <span class="align-middle ml-50">Detail</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="$can('update', 'Inventory Facility')"
                      v-b-modal.modal-upload
                      @click="handleUpload(item.id)"
                    >
                      <feather-icon icon="UploadIcon" />
                      <span class="align-middle ml-50">Upload Image</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="$can('update', 'Inventory Facility')"
                      v-b-modal.modal-form
                      @click="handleEdit(item)"
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="$can('update', 'Inventory Facility')"
                      @click="handleDelete(item.id)"
                    >
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-td>
              </b-tr>
            </template>
          </b-tbody>
        </b-table-simple>
        <!-- <b-table
          class="position-relative"
          :items="fetchData"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(show_details)="row">
            <b-form-checkbox
              v-model="row.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="row.toggleDetails"
            >
              <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
            </b-form-checkbox>
          </template>

          <template #row-details="row">
            <b-list-group>
              <b-list-group-item
                v-for="(item, index) in row.item.inventories"
                :key="index"
              >
                <b-row>
                  <b-col
                    cols="1"
                    md="1"
                  >
                    {{ index + 1 }}
                  </b-col>
                  <b-col
                    cols="3"
                    md="3"
                  >
                    <span>{{ item.name }}</span>
                  </b-col>
                  <b-col
                    cols="2"
                    md="2"
                  >
                    <span>{{ item.inventory_code }}</span>
                  </b-col>
                  <b-col
                    cols="2"
                    md="2"
                    class="text-center"
                  >
                    <b-badge
                      pill
                      :variant="`light-${resolverStatus(item.status)}`"
                      class="text-capitalize"
                    >
                      {{ translateStatus(item.status) }}
                    </b-badge>
                    <br>
                    <small
                      v-if="item.status == 'unavailable' && item.available_date"
                      class="text-small text-secondary"
                    >
                      s.d {{ item.available_date }}
                    </small>
                  </b-col>
                  <b-col
                    cols="2"
                    md="2"
                  >
                    <span>{{ item.location }}</span>
                  </b-col>
                  <b-col
                    cols="1"
                    md="1"
                  >
                    <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >

                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>

                      <b-dropdown-item
                        v-b-modal.modal-detail
                        @click="handleDetail(item.id)"
                      >
                        <feather-icon icon="EyeIcon" />
                        <span class="align-middle ml-50">Detail</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="$can('update', 'Inventory Tool')"
                        v-b-modal.modal-upload
                        @click="handleUpload(item.id)"
                      >
                        <feather-icon icon="UploadIcon" />
                        <span class="align-middle ml-50">Upload Image</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="$can('update', 'Inventory Tool')"
                        v-b-modal.modal-form
                        @click="handleEdit(item)"
                      >
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Edit</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="$can('update', 'Inventory Tool')"
                        @click="handleDelete(item.id)"
                      >
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">Delete</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </template>
        </b-table> -->

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ fetchMeta.current_page }} from {{ fetchMeta.last_page }} Pages</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="fetchMeta.total"
                :per-page="page.size"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <ModalDetail
      :modal-title="modalTitle"
      :detail="formData"
      @translate-status="translateStatus($event)"
      @refresh="getInventoryDetail($event)"
      @success="successToast()"
      @error="errorToast($event)"
    />
    <ModalForm
      :modal-title="modalTitle"
      :form="formData"
      :category-list="categoryList"
      @refresh="getInventoryList()"
      @success="successToast()"
      @error="errorToast($event)"
    />
    <ModalUpload
      :detail="formData"
      @refresh="getInventoryDetail($event)"
      @success="successToast()"
      @error="errorToast($event)"
    />
    <ModalImport
      modal-title="Import Data"
      @refresh="getInventoryList()"
      @success="successToast()"
      @error="errorToast($event)"
    />
  </div>
</template>
<script>
import {
  BCard, BOverlay, BRow, BCol, BFormInput, BButton, BTable, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot, BPagination, BFormSelect, BFormCheckbox, BListGroup, BListGroupItem, BDropdown, BDropdownItem, BBadge,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import ModalDetail from './ModalDetail.vue'
import ModalForm from './ModalForm.vue'
import ModalUpload from './ModalUpload.vue'
import ModalImport from './ModalImport.vue'

export default {
  components: {
    ModalDetail,
    ModalForm,
    ModalUpload,
    ModalImport,

    BCard,
    BOverlay,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BBadge,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BTableSimple, 
    BThead, 
    BTr, 
    BTh, 
    BTd, 
    BTbody, 
    BTfoot,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      overlay: true,
      currentPage: 1,
      searchQuery: '',
      searchCategory: null,
      searchStatus: null,
      fetchData: [],
      fetchMeta: {},
      tableColumns: [
        { key: 'show_details' },
        { key: 'name', label: 'nama' },
        { key: 'code', label: 'kode' },
        { key: 'total', label: 'total' },
      ],
      page: {
        size: 10,
        number: 1,
      },
      filter: {
        search: '',
        category_id: null,
      },
      modalTitle: '',
      formData: {},
      categoryList: [],
      statusList: [
        { text: 'Select Status', value: null },
        { text: 'Available', value: 'available' },
        { text: 'Not Available', value: 'unavailable' },
      ],
      isSmallTable: true,
    }
  },
  watch: {
    currentPage() {
      this.page.number = this.currentPage
      this.getInventoryList()
    },
    searchQuery() {
      this.filter.search = this.searchQuery
      this.getInventoryList()
    },
    searchCategory() {
      this.filter.category_id = this.searchCategory
      this.getInventoryList()
    },
    searchStatus() {
      this.filter.status = this.searchStatus
      this.getInventoryList()
    },
  },
  mounted() {
    this.getCategoryList()
    this.getInventoryList()
  },
  methods: {
    getInventoryList() {
      this.overlay = true
      const data = {
        params: {
          'page[number]': this.page.number,
          'page[size]': this.page.size,
          'filter[id]': this.filter.category_id,
          search: this.filter.search,
          status: this.filter.status,
        },
      }

      this.$http.get('/v1/inventory/list-group/tool', data)
        .then(response => {
          this.setFetchData(response.data.data)
          this.setFetchMeta(response.data.meta)
          this.overlay = false
        })
        .catch(error => {
          this.errorToast(error.response.data.messages)
        })
    },
    getCategoryList() {
      const data = {
        params: {
          'page[size]': 0,
        },
      }

      this.$http.get('/v1/category/list', data)
        .then(response => {
          this.categoryList = response.data.data.map(item => ({
            value: item.id,
            text: item.name,
          }))

          this.categoryList.unshift({
            value: null,
            text: 'Select Category',
          })
        })
        .catch(error => {
          this.errorToast(error.response.data.messages)
        })
    },
    getInventoryDetail(id) {
      this.$http.get(`/v1/inventory/${id}`)
        .then(response => {
          this.formData = response.data.data
        })
        .catch(error => {
          this.errorToast(error.response.data.messages)
        })
    },
    setFetchData(data) {
      this.fetchData = data
    },
    setFetchMeta(data) {
      this.fetchMeta = data
    },
    handleDetail(id) {
      this.modalTitle = 'Detail Alat'
      this.getInventoryDetail(id)
    },
    handleCreate() {
      this.getCategoryList()
      this.modalTitle = 'Tambah Alat'
      this.formData = {
        type: 'tool',
        category_id: null,
        ownership: 'Akademi Film Yogyakarta',
      }
    },
    handleUpload(id) {
      this.getInventoryDetail(id)
    },
    handleEdit(data) {
      this.getCategoryList()
      this.modalTitle = 'Edit Alat'
      this.formData = data
    },
    handleDelete(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete everything.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$http.delete(`/v1/inventory/${id}`)
              .then(() => {
                this.successToast()
                this.getInventoryList()
              })
              .catch(error => {
                this.errorToast(error.response.data.messages)
              })
          }
        })
    },
    resolverStatus(status) {
      if (status === 'available') {
        return 'success'
      }
      return 'danger'
    },
    translateStatus(status) {
      if (status === 'available') {
        return 'Tersedia'
      }
      return 'Tidak Tersedia'
    },
    successToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Berhasil',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    errorToast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
    handleExport() {
      const filter = {
        category_id: this.filter.category_id,
        type: 'tool',
      }

      this.$http.post('/v1/inventory/export', filter, {
        responseType: 'blob',
      })
        .then(response => {
          const url = URL.createObjectURL(new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `inventory-${new Date().toLocaleDateString()}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          this.errorToast(error.response.data.messages)
        })
    },
  },
}
</script>
