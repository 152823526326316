<template>
  <div>
    <b-modal
      id="modal-detail"
      centered
      hide-footer
      size="lg"
      :title="modalTitle"
      class="pb-3"
    >
      <div
        v-if="detail.id !== undefined"
        class="mb-3"
      >
        <b-row>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Nama Inventaris :"
            >
              <b>{{ detail.name }}</b>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Kategori :"
            >
              <b>{{ detail.category.name }}</b>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Nomor Inventaris :"
            >
              <b>{{ detail.inventory_code }}</b>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Status :"
            >
              <b-badge
                :variant="`light-${resolverStatus(detail.status)}`"
                class="mb-75"
              >
                {{ translateStatus(detail.status) }}
              </b-badge>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Kepemilikan :"
            >
              <b>{{ detail.ownership }}</b>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Deskripsi :"
            >
              <b>{{ detail.description }}</b>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="$can('create', 'Facility')"
        >
          <b-col
            md="6"
            cols="12"
          >
            <b-media
              no-body
              class="mt-1"
            >
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-primary"
                  size="34"
                >
                  <feather-icon
                    icon="DollarSignIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">
                  Harga
                </h6>
                <small>{{ detail.price }}</small>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-media
              no-body
              class="mt-1"
            >
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-danger"
                  size="34"
                >
                  <feather-icon
                    icon="AlertCircleIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">
                  Nilai Denda
                </h6>
                <small>{{ detail.debt_cost }}</small>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-media
              no-body
              class="mt-1"
            >
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-warning"
                  size="34"
                >
                  <feather-icon
                    icon="TrendingDownIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">
                  Nilai Susut
                </h6>
                <small>{{ detail.depreciation }}</small>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-media
              no-body
              class="mt-1"
            >
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-info"
                  size="34"
                >
                  <feather-icon
                    icon="CalendarIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">
                  Tanggal Pembelian
                </h6>
                <small>{{ detail.purchase_date }}</small>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>

        <div
          v-if="detail.images.length > 0"
        >
          <h6 class="section-label">
            Images
          </h6>
          <swiper
            class="swiper-multiple"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          >
            <swiper-slide
              v-for="(data) in detail.images"
              :key="data.id"
            >
              <b-img
                :src="data.url_path"
                width="220"
                height="180"
              />
            </swiper-slide>

            <div
              slot="button-next"
              class="swiper-button-next swiper-button-white"
            />
            <div
              slot="button-prev"
              class="swiper-button-prev swiper-button-white"
            />
          </swiper>
        </div>
        <div
          v-if="detail.refinements.length > 0"
          class="mt-2"
        >
          <h6 class="section-label">
            Log Perbaikan
          </h6>

          <b-table
            class="position-relative"
            :items="detail.refinements"
            small="true"
            :fields="refinementColumns"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
          >
            <template #cell(no)="data">
              {{ data.index + 1 }}
            </template>
            <template
              #cell(actions)="data"
            >
              <b-dropdown
                v-if="data.item.status === 'Belum diperbaiki'"
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item
                  v-b-modal.modal-form-land
                  @click="handleUpdateRefinement(data.item.id)"
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Selesai diperbaiki</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, VBModal, BBadge, BImg, BRow, BCol, BMedia, BMediaAside, BMediaBody, BAvatar, BFormGroup, BTable, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import Ripple from 'vue-ripple-directive'
import 'swiper/css/swiper.css'

export default {
  components: {
    BModal,
    BBadge,
    BImg,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormGroup,
    BTable,
    BDropdown,
    BDropdownItem,

    Swiper,
    SwiperSlide,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    modalTitle: {
      type: String,
      default: 'Detail Alat',
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      errors: [],
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 10,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      refinementColumns: [
        {
          key: 'no',
          label: 'No',
        },
        {
          key: 'refinement_date',
          label: 'Tanggal',
        },
        {
          key: 'condition',
          label: 'Kondisi',
        },
        {
          key: 'description',
          label: 'Deskripsi',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
    }
  },
  methods: {
    resolverStatus(status) {
      if (status === 'available') {
        return 'success'
      }
      return 'danger'
    },
    handleUpdateRefinement(id) {
      this.$http.put(`/v1/refinement/${id}`)
        .then(() => {
          this.$emit('success')
          this.$emit('refresh', this.detail.id)
        })
        .catch(error => {
          this.$emit('error', error)
        })
    },
    translateStatus(status) {
      if (status === 'available') {
        return 'Tersedia'
      }
      return 'Tidak Tersedia'
    },
  },
}
</script>
