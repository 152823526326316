<template>
  <div>
    <b-modal
      id="modal-form"
      centered
      hide-footer
      size="lg"
      :title="modalTitle"
    >
      <b-card-text>
        <b-alert
          v-if="errors.length > 0"
          variant="danger"
          show
        >
          <div class="alert-body">
            <p
              v-for="error in errors"
              :key="error"
            >
              {{ error }}
            </p>
          </div>
        </b-alert>
        <b-row>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Nama"
            >
              <b-form-input
                v-model="form.name"
                placeholder="Enter Name"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Kategori"
            >
              <b-form-select
                v-model="form.category_id"
                :options="categoryList"
                placeholder="Select Category"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            cols="12"
          >
            <b-form-group
              label="Deskripsi"
            >
              <b-form-textarea
                v-model="form.description"
                placeholder="Enter Description"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Tanggal Pembelian"
            >
              <flat-pickr
                v-model="form.purchase_date"
                class="form-control"
                :config="flatpickrConfig"
                :style="{ width: '365px' }"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Harga"
            >
              <b-form-input
                v-model="form.price"
                placeholder="Enter Price"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Nilai Denda"
            >
              <b-form-input
                v-model="form.debt_cost"
                placeholder="Enter Debt"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Nilai Susut"
            >
              <b-form-input
                v-model="form.depreciation"
                placeholder="Enter Depreciation"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Kepemilikan"
            >
              <b-form-input
                v-model="form.ownership"
                placeholder="Enter Ownership"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Status"
            >
              <b-form-select
                v-model="form.status"
                :options="statusOptions"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Lokasi"
            >
              <b-form-input
                v-model="form.location"
                placeholder="Enter Location"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Kondisi"
            >
              <b-form-checkbox
                v-model="form.is_active"
                checked="true"
                name="check-button"
                switch
                inline
              >
                Aktif
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="handleSave()"
        >
          <feather-icon
            icon="CheckIcon"
            class="mr-50"
          />
          <span>Simpan</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BAlert, BModal, VBModal, BCardText, BButton, BFormInput, BFormGroup, BFormSelect, BFormTextarea, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BAlert,
    BModal,
    BCardText,
    BButton,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BRow,
    BCol,
    BFormCheckbox,

    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    modalTitle: {
      type: String,
      default: 'Tambah Alat',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    categoryList: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      errors: [],
      statusOptions: [
        { value: null, text: 'Select Status' },
        { value: 'available', text: 'Tersedia' },
        { value: 'unavailable', text: 'Tidak Tersedia' },
      ],
      flatpickrConfig: {
        static: true,
      },
    }
  },
  mounted: () => {
    if (this.form.purchase_date) {
      this.form.purchase_date = new Date(this.form.purchase_date)
    }
  },
  methods: {
    async handleSave() {
      try {
        if (this.form.id) {
          await this.$http.put(`/v1/inventory/${this.form.id}`, this.form)
        } else {
          await this.$http.post('/v1/inventory', this.form)
        }

        this.$bvModal.hide('modal-form')
        this.$emit('success')
        this.$emit('refresh')
      } catch (error) {
        this.errors = error.response.data.errors
        this.$emit('error', error.response.data.messages)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
